<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Conversion Value"
          label-for="conversionValue"
        >
          <b-form-rating
            id="conversionValue"
            v-model="starRating"
            name="conversionValue"
            :variant="variantColor[conversionStatus]"
            size="lg"
            no-border
            @change="calculateConversionValue"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" />
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BFormGroup,
  BFormRating,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRating,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: 0,
    },
    // TODO: get conversion value from current customer
    userConversionValue: {
      type: [Number, String],
      default: 0,
    },
    conversionStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      conversionValue: 0,
      starRating: 0,

      conversionRatioTable: {
        lost: [
          { star: 1, ratio: 0 },
          { star: 2, ratio: 0.1 },
          { star: 3, ratio: 0.2 },
          { star: 4, ratio: 0.3 },
          { star: 5, ratio: 0.4 },
        ],
        pending: [
          { star: 0, ratio: 0 },
          { star: 1, ratio: 0.1 },
          { star: 2, ratio: 0.2 },
          { star: 3, ratio: 0.3 },
          { star: 4, ratio: 0.4 },
          { star: 5, ratio: 0.5 },
        ],
      },
      variantColor: {
        verified: 'success',
        lost: 'danger',
        pending: 'warning',
        won: 'success',
      },
    }
  },
  methods: {
    calculateConversionValue(starRating) {
      // Convert star rating to conversion value for conversionActionFormData
      const conversionStatus = this.conversionStatus === 'lost' ? 'lost' : 'pending'
      let calculatedValue = 0
      if (conversionStatus && starRating && this.userConversionValue) {
        const mathRatio = this.conversionRatioTable[conversionStatus].find(item => starRating === item.star) || 0
        calculatedValue = (parseFloat(this.userConversionValue) * mathRatio.ratio * 0.01).toFixed(2)
      }
      this.conversionValue = calculatedValue
      this.$emit('setConversionValue', calculatedValue)
    },
  },
}
</script>
